import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Imprint from '@/views/Imprint'
import Privacy from '@/views/Privacy'
import Stuhrversum from '@/views/Stuhrversum'
import PageNotFound from '@/views/PageNotFound'
import { store } from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },{
        path: '/impressum',
        name: 'Impressum',
        component: Imprint
    },{
        path: '/privacy',
        name: 'Datenschutzerklärung',
        component: Privacy
    },{
        path: '/stuhrversum',
        name: 'Stuhrversum',
        component: Stuhrversum
    },{
        path: "*",
        name: '404',
        component: PageNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        const headerHeight = document.getElementById('header').offsetHeight
        const scrollPos = window.scrollY
        const windowWidth = window.innerWidth
        if(scrollPos <= headerHeight && windowWidth > 560) {
            return {
                x: 0,
                y: scrollPos,
                behavior: 'smooth'
            }
        } else if(windowWidth > 560) {
            return {
                x: 0,
                y: headerHeight - 150,
                behavior: 'smooth'
            }
        } else {
            return {
                x: 0,
                y: 0,
                behavior: 'smooth'
            }
        }
    }
})

router.beforeEach(function(to, from, next){
    // handle cookie manager
    const noObstruct = [
        'Impressum',
        'Datenschutzerklärung'
    ]
    if(
        !noObstruct.includes(to.name) &&
        !store.state.cookieManagerConfigured
    ){
        store.commit('openCookieManager')
    }
    else {
        store.commit('closeCookieManager')
    }
    next()
})

export default router
