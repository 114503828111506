<template>
    <div class="nav">
        <div class="inner-wrapper">
            <div class="elements">

                <BurgerMenu/>

                <div
                    class="dropdown logo"
                    @mouseover="dropdownOpen = true"
                    @mouseleave="dropdownOpen = false"
                >
                    <img class="logo-image" :src="logoSrc">
                    <div class="dropdown-content" :class="{'open': dropdownOpen}">
                        <router-link class="dropdown-item active suw" to="/">
                            <img class="dropdown-logo" :src="logoSrc">
                            Stuhrblick
                        </router-link>
                        <a class="dropdown-item sub" href="https://stuhrwerk.de">
                            <img class="dropdown-logo suw" src="@/assets/stuhrwerk_logo.svg"/>
                            Stuhrwerk
                        </a>
                    </div>
                </div>

                <div class="logo mobile">
                    <router-link to="/">
                        <img :src="logoSrc">
                    </router-link>
                </div>

                <MainRouterLinks class="nav-router-links"/>

                <div class="external-links">
                    <div class="yt" v-if="$store.state.allowEmbeds.youtube">
                        <a target="_blank" href="https://www.youtube.com/channel/UCqkzkfMivQiKXu2A9cNIUmA">
                            <img src="@/assets/yt_icon_rgb.png">
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import BurgerMenu from '@/components/BurgerMenu'
    import MainRouterLinks from '@/components/MainRouterLinks'

    export default {
        components: {
            BurgerMenu,
            MainRouterLinks
        },

        data() {
            return {
                dropdownOpen: false
            }
        },

        computed: {
            logoSrc() {
                return require('@/assets/logo_half_closed.svg')
            }
        },

        methods: {
            toggleDropdown() {
                this.dropdownOpen = !this.dropdownOpen
            }
        }
    }
</script>

<style lang="scss">
    .nav {
        position: fixed;
        top: 0;
        height: 72px;
        width: 100vw;
        background: rgba(20,20,20,0.9);
        z-index: 980;
        backdrop-filter: blur(20px) saturate(2) brightness(2);
        box-shadow: 0 10px 30px rgba(0,0,0,0.5);

        @include breakpoint('max') {
            padding: 0 20px;
            width: calc(100vw - 40px);
        }

        @include breakpoint('mobile') {
            height: 48px;
            padding: 0 20px;
        }

        .inner-wrapper {
            max-width: 1200px;
            margin: 0 auto;
            height: 100%;

            @include breakpoint('max') {
                max-width: unset;
            }
        }

        .elements {
            display: flex;
            align-items: center;
            height: 100%;
            user-select: none;

            @include breakpoint('mobile') {
                justify-content: space-between;
            }

            .dropdown {
                @include breakpoint('mobile') {
                    display: none;
                }
            }

            .mobile {
                display: none;

                @include breakpoint('mobile') {
                    display: block;
                }
            }

            .logo {
                height: 50%;
                margin-right: 20px;
                text-align: center;
                cursor: pointer;
                position: relative;

                .logo-image {
                    height: 100%;
                }

                .dropdown-content {
                    display: block;
                    /* flex-direction: column; */
                    position: absolute;
                    background: $dark2;
                    color: $light2;
                    min-width: 100px;
                    left: -19px;
                    top: -18px;
                    /* box-shadow: $boxShadowXL; */
                    height: 0px;
                    overflow: hidden;
                    transition: .25s;

                    &.open {
                        height: 136px;
                    }
                }

                @include breakpoint('mobile') {
                    margin: 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    top: 9px;
                }

                .dropdown-item {
                    font-family: 'Rock Salt', cursive;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: $light2;
                    margin-left: 19px;
                    margin-right: 30px;
                    transition: .25s;
                    transition: .25s;

                    &.active {
                        color: $white;
                    }

                    &:hover {
                        color: $white;
                    }

                    &.suw {
                        height: 72px;
                    }

                    &.sub {
                        height: 54px;
                    }

                    .dropdown-logo {
                        height: 36px;
                        margin-right: 20px;

                        &.suw {
                            filter: invert(1);
                        }
                    }
                }

                a {
                    display: inline-block;
                    height: 100%;

                    @include breakpoint('mobile') {
                        height: 120%;
                    }

                    img {
                        height: 100%;
                    }
                }
            }

            .menu.nav-router-links {
                display: flex;
                flex-grow: 1;

                @include breakpoint('mobile') {
                    display: none;
                }

                a {
                    text-decoration: none;
                }

                .menu-item {
                    height: 100%;
                    color: $light1;
                    font-family: 'Rock Salt', cursive;
                    line-height: 72px;
                    margin: 0 20px;
                    text-decoration: none;
                    transition: .25s;

                    &:hover {
                        color: $white;
                    }

                    @include breakpoint('mobile') {
                        line-height: 48px;
                        margin: 0 10px;
                    }
                }
            }

            .external-links {
                height: 50%;
                display: flex;

                .yt,
                .stw {
                    height: 100%;
                    opacity: 0.75;
                    transition: .5s;

                    &:hover {
                        opacity: 1;
                    }

                    img {
                        height: 100%;
                    }
                }
                .yt {
                    height: 90%;
                }
                .stw {
                    margin-left: 30px;
                    filter: invert(1);

                    @include breakpoint('mobile') {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
</style>
