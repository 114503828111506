<template>
    <div
        class="overlay"
        :class="classes"
        @click="$emit('click')"
    ></div>
</template>

<script>
    export default {
        name: 'Overlay',

        props: [
            'open'
        ],

        computed: {
            classes() {
                return {
                    'open': this.open
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        width: 100vw;
        background: black;
        opacity: 0;
        pointer-events: none;
        transition: .25s;
        z-index: 995;

        &.open {
            opacity: .9;
            pointer-events: all;
        }
    }
</style>
