<template>
    <div class="text-page">
        <div class="row">
            <div class="col left">
                <img class="logo" src="@/assets/suw_logo.png"/>
            </div>
            <div class="col right">
                <h1>Stuhrwerk</h1>
                <h3>Das Mutterschiff</h3>
                <p>Kreativlabel aus NRW</p>
                <p>|| SUW - Wir werkeln stur wie die Uhr ||</p>
                <a href="https://stuhrwerk.de" target="_blank">Stuhrwerk.de</a>
                <br>
                <br>
                <p>Guckst du hier:</p>
                <div class="icons">
                    <a href="https://www.instagram.com/stuhrwerk" target="_blank">
                        <img src="@/assets/insta_logo.png"/>
                    </a>
                    <a href="https://www.facebook.com/stuhrwerk/" target="_blank">
                        <img src="@/assets/fb_logo.png"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UC5UMHL1fW2WFjhWq4_YGzEQ" target="_blank">
                        <img src="@/assets/yt_icon_rgb.png"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Stuhrversum'
    }
</script>

<style lang="scss" scoped>
    .row {
        display: flex;

        @include breakpoint('mobile') {
            flex-wrap: wrap;
        }

        .col {
            width: 50%;
            padding: 20px;

            @include breakpoint('mobile') {
                width: 100%;
            }

            .logo {
                width: 100%;
                height: auto;
                opacity: 0.5;
                transition: .25s;

                &:hover {
                    opacity: 1;
                }
            }

            &.right {
                text-align: start;

                .icons {
                    display: flex;
                    align-items: center;

                    img {
                        max-width: 48px;
                        margin: 10px;
                        opacity: 0.5;
                        transition: .25s;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
</style>
