<template>
    <div id="app">
        <Header id="header"/>
        <div class="page-content">
            <transition name="blur">
                <router-view/>
            </transition>
        </div>
        <Footer/>
        <CookieManager/>
    </div>
</template>

<script>
    import Footer from '@/components/Footer'
    import Header from '@/components/Header'
    import CookieManager from '@/components/CookieManager'

    export default {
        name: 'App',

        components: {
            Header,
            Footer,
            CookieManager
        }
    }
</script>

<style lang="scss">
    body {
        background: $black;
        margin: 0;
    }

    #app {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        .page-content {
            flex-grow: 1;
        }
    }

    .blur-enter-active,
    .blur-leave-active {
        transition: .25s;
    }

    .blur-enter,
    .blur-leave-to {
        transform-origin: top;
        transform: scale(0.8);
        filter: blur(3px);
        opacity: 0;
    }
</style>
