<template>
    <div class="text-page">
        <h1>404 - Not found!</h1>
    </div>
</template>

<script>
    export default {
        name: 'PageNotFound'
    }
</script>
