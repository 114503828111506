export const config = {
    baseCms: process.env.VUE_APP_BASE_CMS || 'https://strapi.stuhrblick.de',
    matomoHost: process.env.VUE_APP_MATOMO_HOST || 'https://matomo.stuhrblick.de',
    matomoSiteId: process.env.VUE_APP_MATOMO_SITE_ID
}

export const configPlugin = {
    install(Vue) {
        Vue.prototype.$config = config
    }
}
