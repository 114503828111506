<template>
    <div class="wrapper">
        <video loading="lazy" class="video-bg" poster="@/assets/poster.jpg" autoplay muted loop>
            <source loading="lazy" src="@/assets/web_bg.mp4" type="video/mp4">
        </video>
        <div class="img-wrapper" ref="baseEye">
            <img loading="lazy" v-show="openEyeVisible" :src="openEye"/>
            <img loading="lazy" v-show="halfClosedEyeVisible" :src="halfClosedEye"/>
            <img loading="lazy" v-show="closedEyeVisible" :src="closedEye"/>
        </div>
        <div class="img-wrapper" :class="{'hide': !eyeOpened}">
            <img loading="lazy" ref="pupil" :src="pupil" :style="pupilPositionCss"/>
        </div>
    </div>
</template>

<script>
    import { VecCartesian, VecPolar } from '@/classes/vectors'

    export default {
        name: 'StuhrEye',

        data() {
            return {
                pupilPosition: new VecCartesian(0, 0),
                offset: new VecCartesian(10, 15),
                lastCursorPosition: new VecCartesian(0, 0),

                limits: {
                    desktop: 300,
                    tablet: 195
                },

                currentBaseEye: null,
                eyeOpened: null,

                openEye: require('@/assets/open_black_blurred.png'),
                halfClosedEye: require('@/assets/halfclosed_black_blurred.png'),
                closedEye: require('@/assets/closed_black_blurred.png'),
                pupil: require('@/assets/pupil_black_blurred.png'),

                openEyeVisible: true,
                halfClosedEyeVisible: false,
                closedEyeVisible: false
            }
        },

        computed: {
            pupilPositionCss() {
                return {
                    transform: `translate(${this.pupilPosition.x}px, ${this.pupilPosition.y}px)`
                }
            }
        },

        methods: {
            transformToPolar(vecCartesian) {
                var r = Math.sqrt(Math.pow(vecCartesian.x, 2) + Math.pow(vecCartesian.y, 2))
                var phi = Math.atan2(vecCartesian.y, vecCartesian.x)
                return new VecPolar(r, phi)
            },

            transformToCartesian(vecPolar) {
                var x = vecPolar.r * Math.cos(vecPolar.phi)
                var y = vecPolar.r * Math.sin(vecPolar.phi)
                return new VecCartesian(x, y)
            },

            getOrigin() {
                var rect = this.$refs.baseEye.getBoundingClientRect()
                var x = Math.floor(rect.x + rect.width / 2.15)
                var y = Math.floor(rect.y + rect.height / 2)
                return new VecCartesian(x, y)
            },

            getRelativeCursorPosition(origin, cursor) {
                return new VecCartesian(
                    cursor.x - origin.x,
                    cursor.y - origin.y
                )
            },

            setPositionByEvent(event) {
                this.lastCursorPosition.set(event.clientX, event.clientY)
                this.setPositionByCursor(event.clientX, event.clientY)
            },

            setPositionByCursor(x, y) {
                var limit = window.innerWidth > 900 ? this.limits.desktop : this.limits.tablet

                var origin = this.getOrigin()
                var cursor = new VecCartesian(x, y)

                var relative = this.transformToPolar(this.getRelativeCursorPosition(origin, cursor))

                relative.r = relative.r <= limit ? relative.r : limit

                var cartesian = this.transformToCartesian(relative)

                this.pupilPosition.set(
                    Math.floor(cartesian.x / 7.5 + this.offset.x),
                    Math.floor(cartesian.y / 9.5 + this.offset.y)
                )
            },

            setOpen() {
                this.eyeOpened = true
                this.openEyeVisible = true
                this.halfClosedEyeVisible = false
                this.closedEyeVisible = false
            },

            setHalfClosed() {
                this.eyeOpened = true
                this.openEyeVisible = false
                this.halfClosedEyeVisible = true
                this.closedEyeVisible = false
            },

            setClosed() {
                this.eyeOpened = false
                this.openEyeVisible = false
                this.halfClosedEyeVisible = false
                this.closedEyeVisible = true
            },

            initPupilEventListeners() {
                document.addEventListener('mousemove', this.setPositionByEvent)
                this.$refs.pupil.addEventListener('mouseenter', this.setHalfClosed)
                this.$refs.pupil.addEventListener('mouseleave', this.setOpen)
            },

            removePupilEventListeners() {
                document.addEventListener('mousemove', this.setPositionByEvent)
                this.$refs.pupil.removeEventListener('mouseenter', this.setHalfClosed)
                this.$refs.pupil.removeEventListener('mouseleave', this.setOpen)
            },

            initClickEventListener() {
                this.$refs.pupil.addEventListener('click', function(event){
                    this.removePupilEventListeners()
                    this.setClosed()
                    this.resetEyeByCursorPosition()
                }.bind(this))
            },

            resetEyeByCursorPosition() {
                if(this.cursorInEye()){
                    setTimeout(function() {
                        this.setHalfClosed()
                        this.initPupilEventListeners()
                    }.bind(this), 300)
                } else {
                    setTimeout(function() {
                        this.setHalfClosed()
                    }.bind(this), 300)
                    setTimeout(function() {
                        this.setOpen()
                        this.initPupilEventListeners()
                    }.bind(this), 400)
                }
            },

            cursorInEye() {
                var rect = this.$refs.pupil.getBoundingClientRect()
                return (this.lastCursorPosition.x >= rect.x &&
                        this.lastCursorPosition.x <= rect.x + rect.width &&
                        this.lastCursorPosition.y >= rect.y &&
                        this.lastCursorPosition.y <= rect.y + rect.height)
            },

            blink() {
                this.removePupilEventListeners()
                this.setHalfClosed()

                setTimeout(function() {
                    this.setClosed()
                }.bind(this), 100)

                this.resetEyeByCursorPosition()
            },

            executeAutonomy() {
                var rand = Math.round(Math.random() * 8000) + 3000
                setTimeout(function() {
                    this.blink()
                    this.executeAutonomy()
                }.bind(this), rand)
            }
        },

        mounted() {
            this.setOpen()
            this.initPupilEventListeners()
            this.initClickEventListener()
            this.executeAutonomy()
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 72px;

        @include breakpoint('mobile') {
            display: none;
        }

        .video-bg {
            width: 100%;
            height: auto;
            filter: brightness(1.3);

            @include breakpoint('headerL') {
                max-height: 450px;
                object-fit: cover;
            }
        }

        .img-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            overflow: hidden;

            &.hide {
                display: none;
            }

            img {
                max-width: 400px;
                user-select: none;
                filter: blur(1px);
                opacity: 0.9;

                @include breakpoint('headerM') {
                    max-width: 300px;
                }
                @include breakpoint('headerS') {
                    max-width: 220px;
                }
            }
        }
    }
</style>
