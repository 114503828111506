import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueApollo from 'vue-apollo'
import ApolloClient from 'apollo-boost'
import VueMatomo from 'vue-matomo'
import { config, configPlugin } from '@/config'
import { storePlugin } from '@/store'
import { prototypesPlugin } from '@/prototypes'

Vue.use(VueApollo)

const apolloClient = new ApolloClient({
    uri: `${config.baseCms}/graphql`
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})

Vue.config.productionTip = false

Vue.use(configPlugin)
Vue.use(storePlugin)
Vue.use(prototypesPlugin)

Vue.use(VueMatomo, {
    host: config.matomoHost,
    siteId: config.matomoSiteId,
    trackerFileName: 'matomo',
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: true,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
    router: router
})

new Vue({
  router,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
