export const prototypesPlugin = {
    install(Vue) {
        Vue.prototype.$setBodyLock = function() {
            if(this.$store.state.cookieManagerOpen){
                document.body.classList.add('lock')
            }
            else {
                document.body.classList.remove('lock')
            }
        }
    }
}
