import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        allowEmbeds: {
            youtube: false
        },
        cookieManagerConfigured: false,
        cookieManagerOpen: false
    },

    mutations: {
        allowEmbed(state, type) {
            state.allowEmbeds[type] = true
        },

        forbidEmbed(state, type) {
            state.allowEmbeds[type] = false
        },

        setCookieManagerConfigured(state) {
            state.cookieManagerConfigured = true
        },

        openCookieManager(state) {
            state.cookieManagerOpen = true
        },

        closeCookieManager(state) {
            state.cookieManagerOpen = false
        }
    },

    plugins: [vuexLocal.plugin]
})

export const storePlugin = {
    install(Vue) {
        Vue.prototype.$store = store
    }
}
