<template>
    <div class="home">
        <YouTube v-for="video in videos" :key="video.id"
            :isPlaceholder="video.YouTubeVideoId ? false : true"
            :videoId="video.YouTubeVideoId"
            :thumbnail="getImageUrl(video.Thumbnail ? video.Thumbnail.url : null)"
        />
    </div>
</template>

<script>
    import YouTube from '@/components/YouTube'
    import gql from 'graphql-tag'

    export default {
        name: 'Home',

        components: {
            YouTube
        },

        data() {
            return {
                videos: [
                    {}, {}, {}
                ]
            }
        },

        apollo: {
            videos: {
                query: gql`query {
                    videos(sort: "id:desc") {
                        id
                        Title
                        Description
                        YouTubeVideoId
                        Thumbnail {
                            url
                        }
                        Gallery {
                            url
                        }
                    }
                }`
            }
        },

        methods: {
            getImageUrl(url) {
                if(url){
                    return `${this.$config.baseCms}${url}`
                }
                else {
                    return null
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .home {
        margin: 0 auto;
        padding: 20px;
        max-width: 1200px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px 20px;

        @include breakpoint('tablet') {
            grid-template-columns: 1fr 1fr;
        }
        @include breakpoint('mobile') {
            grid-template-columns: 1fr;
            margin-top: 48px;
        }
    }
</style>
