<template>
    <div class="burger-menu">

        <div class="icon" v-on:click="toggle">
            <img src="@/assets/burger.svg"/>
        </div>

        <div class="drawer" :class="{'open': isOpen}">

            <div class="icon" v-on:click="toggle">
                <img src="@/assets/x.svg"/>
            </div>

            <MainRouterLinks class="burger-router-links"/>

            <FooterRouterLinks class="footer-router-links"/>

            <div class="menu burger-router-links">
                <a v-if="loggedIn" href="#" @click="logout">Logout</a>
            </div>
        </div>

    </div>
</template>

<script>
    import MainRouterLinks from '@/components/MainRouterLinks'
    import FooterRouterLinks from '@/components/FooterRouterLinks'

    export default {
        components: {
            MainRouterLinks,
            FooterRouterLinks
        },

        data() {
            return {
                isOpen: false,
                loggedIn: false
            }
        },

        methods: {
            toggle() {
                this.isOpen = !this.isOpen
            },

            logout() {
                sessionStorage.removeItem('jwt')
                sessionStorage.removeItem('entries')
                this.loggedIn = false
            }
        },

        watch: {
            $route(to, from){
                this.isOpen = false
            }
        },

        mounted() {
            if(sessionStorage.getItem('jwt')){
                this.loggedIn = true
            }
        }
    }
</script>

<style lang="scss">
    .burger-menu {
        display: none;
        z-index: 10;

        @include breakpoint('mobile') {
            display: unset;
        }

        .icon {
            width: 30px;
            height: 30px;
            cursor: pointer;
            opacity: 0.75;
            transition: .25s;

            &:hover {
                opacity: 1;
            }
        }

        .drawer {
            position: fixed;
            top: 0;
            height: 100vh;
            left: calc(-80vw - 40px);
            width: calc(80vw - 40px);
            padding: 10px 20px;
            transition: .25s;
            background: $dark1;

            &.open {
                left: 0;
                box-shadow: 11px 0px 50px 5px #000000;
            }

            .menu.burger-router-links,
            .links.footer-router-links {
                display: flex;
                flex-direction: column;
                margin-top: 40px;
                align-items: flex-start;

                a {
                    text-decoration: none;
                }

                .menu-item,
                a {
                    font-family: 'Rock Salt', cursive;
                    color: $light1;
                    text-decoration: none;
                    transition: .25s;

                    &:hover {
                        color: $white;
                    }
                }

            }
        }
    }
</style>
