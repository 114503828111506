<template>
    <div class="rendering-area">
        <div v-if="isPlaceholder" class="placeholder"></div>
        <div
            class="youtube"
            v-if="$store.state.allowEmbeds.youtube"
            @click="play = true"
        >
            <img v-if="!isPlaceholder && !play" :src="thumbnail"/>
            <iframe
                v-if="!isPlaceholder && play"
                :src="src"
                class="iframe"
                frameborder="0"
                allowfullscreen="true"
            ></iframe>
        </div>
        <ForbiddenPlayer
            v-else
            class="forbidden"
            embed="youtube"
            :small="true"
        >
            Du hast das Einbinden von YouTube Videos auf Stuhrwerk.de verboten. Klicke unten, um diese Einstellung zu ändern.
            <br>
            <br>
            Weitere Informationen zu eingebundenen Inhalten findest du in unserer <router-link to="/privacy" @click.native="$store.commit('closeCookieManager')">Datenschutzerklärung</router-link>.
            <br>
            <br>
        </ForbiddenPlayer>
    </div>
</template>

<script>
    import ForbiddenPlayer from '@/components/ForbiddenPlayer'

    export default {
        name: 'YouTube',

        components: {
            ForbiddenPlayer
        },

        props: [
            "isPlaceholder",
            "videoId",
            "thumbnail"
        ],

        data() {
            return {
                play: false
            }
        },

        computed: {
            src() {
                if(this.videoId){
                    return `https://www.youtube-nocookie.com/embed/${this.videoId}`
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rendering-area {
        background: transparent;
        height: 0;
        padding-bottom: calc(56.25%);
        position: relative;
        width: 100%;
    }

    .iframe, img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%
    }

    img {
        object-fit: cover;
        cursor: pointer;
    }

    .placeholder {
        background: #151515;
        position: absolute;
        width: 100%;
        height: 100%;
        animation: loading 2s ease 0s infinite normal forwards;
    }

    @for $i from 1 through 3 {
        .rendering-area:nth-child(#{$i}n) .placeholder {
            animation-delay: #{$i * -0.125}s;
        }
    }

    @keyframes loading {
        0%, 50%, 100% {
            opacity: 1;
        }
        25%, 75% {
            opacity: 0.5;
        }
    }
</style>
