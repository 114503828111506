<template>
    <div>
        <NavBar/>
        <StuhrEye/>
    </div>
</template>

<script>
    import NavBar from '@/components/NavBar'
    import StuhrEye from '@/components/StuhrEye'

    export default {
        name: 'Header',

        components: {
            NavBar,
            StuhrEye
        }
    }
</script>
