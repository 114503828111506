export class VecCartesian {
    constructor(x, y) {
        this.x = x
        this.y = y
    }

    set(x, y) {
        this.x = x
        this.y = y
    }
}

export class VecPolar {
    constructor(r, phi) {
        this.r = r
        this.phi = phi
    }

    set(r, phi) {
        this.r = r
        this.phi = phi
    }
}
