<template>
    <div class="footer">
        <div class="row">© 2021 - Stuhrblick</div>
        <div class="row">
            <FooterRouterLinks/>
        </div>

        <div class="settings">
            <img
                src="@/assets/cookie.svg"
                @click="$store.commit('openCookieManager')"
            />
        </div>
    </div>
</template>

<script>
    import FooterRouterLinks from '@/components/FooterRouterLinks'

    export default {
        components: {
            FooterRouterLinks
        }
    }
</script>

<style lang="scss">
    .footer {
        padding: 20px;
        background: $dark1;
        margin-top: 40px;
        color: $light1;
        font-family: 'Rock Salt', cursive;
        font-size: 14px;
        position: relative;

        .row {
            text-align: center;
            margin: 10px;
        }

        .links {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            @include breakpoint('mobile') {
                flex-direction: column;
            }

            a {
                color: $light1;
                text-decoration: none;
                transition: .25s;

                &:hover {
                    color: $white;
                }

                &:after {
                    content: "|";
                    margin: 0 10px;

                    @include breakpoint('mobile') {
                        display: none;
                    }
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .settings {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 20px;
            gap: 20px;

            img {
                width: 32px;
                filter: invert(1);
                cursor: pointer;
                transition: .25s;
                opacity: .5;

                &:hover {
                    transform: scale(1.1);
                    opacity: 1;
                }
            }
        }
    }
</style>
